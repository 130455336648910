/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chat': {
    width: 15,
    height: 14,
    viewBox: '0 0 15 14',
    data: '<path pid="0" d="M12.155 0h-9.31C2.09.001 1.367.3.833.832A2.83 2.83 0 000 2.837v9.902a1.232 1.232 0 00.781 1.16c.155.065.32.1.488.101a1.247 1.247 0 00.883-.371l1.98-1.973h8.023a2.856 2.856 0 002.01-.831A2.83 2.83 0 0015 8.823V2.834c0-.751-.3-1.471-.834-2.003A2.855 2.855 0 0012.155 0zm1.908 8.823c-.001.504-.202.986-.56 1.342a1.914 1.914 0 01-1.348.557h-8.22a.531.531 0 00-.372.182L1.49 12.967a.324.324 0 01-.552-.228V2.837c0-.505.2-.988.558-1.345a1.914 1.914 0 011.35-.559h9.309c.506.001.992.202 1.35.559.357.357.558.84.557 1.345v5.986zm-2.554-4.522a.466.466 0 01-.468.467H4.009a.47.47 0 01-.468-.467.466.466 0 01.468-.467h7.032a.47.47 0 01.468.467zm0 3.5a.466.466 0 01-.468.467H4.009a.47.47 0 01-.468-.467.465.465 0 01.468-.467h7.032a.47.47 0 01.468.467z" _fill="#3C8AFF"/>'
  }
})
